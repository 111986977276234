<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card>
    <v-card-text>
      <v-img
        height="100px"
        contain
        :src="urlS3+ catalog.logo"
      ></v-img>
    </v-card-text>
    <v-card-text class="d-flex mt-5">
      <div class="ms-3">
        <p class="text-base font-weight-semibold text--primary mb-0">
          {{ getTitle(catalog) }}
        </p>
        <span class="text-xs">
          <v-tooltip
            color="primary"
            max-width="350"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ getDescripcion(catalog) | truncate(85) }}</span>
            </template>
            <span>{{ getDescripcion(catalog) }}</span>
          </v-tooltip>
        </span>
      </div>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="6">
          <div>
            <v-icon size="30">
              mdi-download-outline
            </v-icon>
          </div>
          <span
            v-if="catalog.cant_download > 0"
            class="text-no-wrap text--primary"
          >
            {{ catalog.cant_download | number('0a') }}
          </span>

          <br />
          <div class="mt-4">
            <v-icon size="30">
              mdi-weight
            </v-icon>
          </div>
          <span class="text-no-wrap text--primary">
            {{ catalog.size_pdf }}
          </span>
        </v-col>
        <v-col cols="3">
          <div>
            <span v-html="qr"></span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            block
            outlined
            @click="download"
          >
            <span>{{ $t('btn.dowload') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiChevronDown, mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    Loading,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    catalog: {
      type: Object,
    },
  },
  setup() {
    return {
      urlRegister: `${process.env.VUE_APP_URL}register`,
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
      developerMeetup: [
        { icon: mdiClockOutline, title: 'Tuesday, 24 january, 10:20 - 12:30', subtitle: 'After 1 week' },
        { icon: mdiMapMarkerOutline, title: 'The Rochard NYC', subtitle: '1305 Lexington Ave, New York' },
      ],
    }
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      qr: null,
      isLoading: false,
    }
  },
  created() {
    this.getQR()
  },
  methods: {
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    getQR() {
      this.axios
        .post('catalogs/qr-catalogo', { id: this.catalog.id })
        .then(res => {
          this.qr = res.data
        })
    },
    async download() {
      this.isLoading = true
      await this.axios
        .post('catalogs/qr-download-catalogo', { id: this.catalog.id })
        .then(res => {
          this.downloadAPK(res.data.data.url)
        })
    },
    async downloadAPK(fileUrl) {
      try {
        // Realiza la solicitud para obtener el PDF
        const response = await fetch(fileUrl)

        // Verifica si la respuesta es correcta
        if (!response.ok) {
          throw new Error('Error al descargar el PDF')
        }

        // Crea un Blob a partir de la respuesta
        const blob = await response.blob()

        // Crea una URL para el Blob
        const url = window.URL.createObjectURL(blob)

        // Crea un elemento <a> y simula un clic en él
        const a = document.createElement('a')
        a.href = url
        a.download = `${this.catalog.title}.pdf` // Nombre que tendrá el archivo al descargarse
        document.body.appendChild(a)
        a.click()

        // Limpia
        a.remove()
        window.URL.revokeObjectURL(url)

        this.isLoading = false
      } catch (error) {
        console.error('Error al descargar el archivo:', error)
      } finally {
        this.isLoading = false
        this.$emit('getCatalogs')
      }
    },
  },
}
</script>
